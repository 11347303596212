import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import styles from './Home.module.css';

const Home = () => {
  const [postcode, setPostcode] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedPostcode = localStorage.getItem('savedPostcode');
    if (savedPostcode) {
      navigate(`/${savedPostcode}`);
    }
  }, [navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (postcode) {
      localStorage.setItem('savedPostcode', postcode);
      navigate(`/${postcode}`);
    }
  };

  const getCurrentLocation = () => {
    setLoading(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await fetch(`https://api.postcodes.io/postcodes?lon=${longitude}&lat=${latitude}`);
              const data = await response.json();
              if (data.result && data.result.length > 0) {
                const nearestPostcode = data.result[0].postcode;
                setPostcode(nearestPostcode);
                setLoading(false);
              } else {
                alert("Unable to find a postcode for your location.");
                setLoading(false);
              }
            } catch (error) {
              console.error("Error fetching postcode:", error);
              alert("An error occurred while fetching your location.");
              setLoading(false);
            }
          },
          (error) => {
            console.error("Error getting location:", error);
            alert("Unable to get your current location. Please make sure you've granted permission.");
            setLoading(false);
          }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
      setLoading(false);
    }
  };

  return (
      <div className={styles.container}>
        <h1 className={styles.title}>Enter Your Postcode</h1>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.inputWrapper}>
            <input
                type="text"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
                placeholder="Enter Postcode"
                className={styles.input}
            />
            <button
                type="button"
                onClick={getCurrentLocation}
                className={styles.locationButton}
                disabled={loading}
            >
              <MapPin size={20} />
            </button>
          </div>
          <button type="submit" className={styles.button} disabled={loading}>
            {loading ? 'Loading...' : 'Check Collection Dates'}
          </button>
        </form>
      </div>
  );
};

export default Home;