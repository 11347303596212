import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle, faTrashAlt, faLeaf, faGlassWhiskey } from '@fortawesome/free-solid-svg-icons'; // Example icons
import styles from './CollectionInfo.module.css';

const API_URL = process.env.REACT_APP_API_URL;

const CollectionInfo = () => {
    const [collectionData, setCollectionData] = useState(null);
    const [error, setError] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [isPWA, setIsPWA] = useState(false);
    const [os, setOS] = useState(null);
    const { postcode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const savedPostcode = localStorage.getItem('savedPostcode');
        if (!postcode && savedPostcode) {
            navigate(`/${savedPostcode}`);
        } else if (postcode) {
            localStorage.setItem('savedPostcode', postcode);
        }

        const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
        setIsPWA(isStandalone);

        const userAgent = window.navigator.userAgent.toLowerCase();
        if (/android/.test(userAgent)) {
            setOS('android');
        } else if (/iphone|ipad|ipod/.test(userAgent)) {
            setOS('ios');
        } else {
            setOS('desktop');
        }

        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/next_single_collection/${postcode}`);
                setCollectionData(response.data);
                setError(null);
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError('No future collections found for the given postcode.');
                } else if (err.response && err.response.status === 500) {
                    setError('Error fetching collection data. Please check the postcode and try again.');
                } else {
                    setError('Error fetching collection data. Please check the postcode and try again.');
                }
            }
        };

        if (postcode) {
            fetchData();
        }
    }, [postcode, navigate]);

    useEffect(() => {
        if (!isPWA && (os === 'android' || os === 'ios')) {
            setShowInstructions(true);
        }
    }, [isPWA, os]);

    const handleChangePostcode = () => {
        localStorage.removeItem('savedPostcode');
        navigate('/');
    };

    const formatPostcode = (postcode) => {
        postcode = postcode.toUpperCase();
        return postcode.length > 3 ? postcode.slice(0, -3) + ' ' + postcode.slice(-3) : postcode;
    };

    const getPreviousDay = (day) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const index = days.indexOf(day);
        return days[(index - 1 + 7) % 7];
    };

    const renderIndicators = (collectionType) => {
        if (collectionType === 'green') {
            return (
                <ul className={styles.indicatorContainer}>
                    <li className={`${styles.indicator} ${styles.green}`}>
                        <FontAwesomeIcon icon={faRecycle} className={styles.indicatorIcon} />
                        <span>Paper & Card</span>
                    </li>
                    <li className={`${styles.indicator} ${styles.green}`}>
                        <FontAwesomeIcon icon={faGlassWhiskey} className={styles.indicatorIcon} />
                        <span>Glass & Cans</span>
                    </li>
                    <li className={`${styles.indicator} ${styles.green}`}>
                        <FontAwesomeIcon icon={faLeaf} className={styles.indicatorIcon} />
                        <span>Food Waste</span>
                    </li>
                    <li className={`${styles.indicator} ${styles.green}`}>
                        <FontAwesomeIcon icon={faLeaf} className={styles.indicatorIcon} />
                        <span>Garden Waste</span>
                    </li>
                </ul>
            );
        } else if (collectionType === 'pink') {
            return (
                <ul className={styles.indicatorContainer}>
                    <li className={`${styles.indicator} ${styles.pink}`}>
                        <FontAwesomeIcon icon={faRecycle} className={styles.indicatorIcon} />
                        <span>Plastic</span>
                    </li>
                    <li className={`${styles.indicator} ${styles.pink}`}>
                        <FontAwesomeIcon icon={faLeaf} className={styles.indicatorIcon} />
                        <span>Food Waste</span>
                    </li>
                    <li className={`${styles.indicator} ${styles.pink}`}>
                        <FontAwesomeIcon icon={faTrashAlt} className={styles.indicatorIcon} />
                        <span>Non-recyclables</span>
                    </li>
                </ul>
            );
        }
        return null;
    };

    const renderContent = () => {
        if (!collectionData && !error) {
            return <div className={styles.loading}>Loading...</div>;
        }

        const previousDay = collectionData ? getPreviousDay(collectionData.day_of_week) : '';

        return (
            <>
                <div className={styles.header}>
                    <h1 className={styles.title}>Next Rubbish Collection</h1>
                    <div className={styles.postcodeContainer}>
                        <span className={styles.postcode}>Postcode: {formatPostcode(postcode)}</span>
                        <button onClick={handleChangePostcode} className={styles.changePostcodeButton}>
                            &#9998;
                        </button>
                    </div>
                </div>
                {error ? (
                    <div className={styles.error}>{error}</div>
                ) : (
                    <>
                        <div className={`${styles.collectionType} ${styles[collectionData.collection_type.toLowerCase()]}`}>
                            {collectionData.collection_type.toUpperCase()} BIN
                        </div>
                        {renderIndicators(collectionData.collection_type.toLowerCase())}
                        <div className={styles.date}>
                            {collectionData.next_collection_date}
                            <br />
                            {collectionData.day_of_week} ({previousDay} Night)
                        </div>
                        <div className={styles.countdown}>
                            {collectionData.days_until_collection === 0
                                ? 'Today is collection day!'
                                : collectionData.days_until_collection === 1
                                    ? 'Tomorrow is collection day!'
                                    : `${collectionData.days_until_collection} days until collection`}
                        </div>
                        <div className={styles.countdown}>
                            {collectionData.days_until_collection === 1 ? "Don't forget to put your bins out tonight!" : ''}
                        </div>
                        <button onClick={() => setShowInfo(!showInfo)} className={styles.toggleButton}>
                            {showInfo ? 'Hide Info' : 'Show Info'}
                        </button>
                        {showInfo && (
                            <div className={styles.infoSection}>
                                <h2>Collection Information</h2>
                                <div className={styles.week}>
                                    <h3>Green Week</h3>
                                    <ul>
                                        <li>Paper & Card - Use green bags</li>
                                        <li>Glass & Cans - Use green bags</li>
                                        <li>Food Waste - Use food waste bags</li>
                                        <li>Garden Waste - Use green bags</li>
                                    </ul>
                                </div>
                                <div className={styles.week}>
                                    <h3>Pink Week</h3>
                                    <ul>
                                        <li>Plastic - Use pink sacks</li>
                                        <li>Food Waste - Use food waste bags</li>
                                        <li>Non-recyclables - Use black bin bags</li>
                                    </ul>
                                </div>
                                <div className={styles.disclaimer}>
                                    <p>Please put your bins out the night before collection day.</p>
                                    <p>Please ensure each section is separated and placed in the correct bags.</p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    };

    return (
        <div className={styles.container}>
            {renderContent()}
            {showInstructions && (
                <div className={styles.instructionsOverlay}>
                    <div className={styles.instructions}>
                        <h2>Install Binday PWA</h2>
                        {os === 'ios' && (
                            <>
                                <h3>For iOS:</h3>
                                <ol>
                                    <li>Tap the 'Share' button (square with an upward arrow).</li>
                                    <li>Select 'Add to Home Screen'.</li>
                                    <li>Tap 'Add' in the top right corner.</li>
                                </ol>
                            </>
                        )}
                        {os === 'android' && (
                            <>
                                <h3>For Android:</h3>
                                <ol>
                                    <li>Tap the menu button (three dots in the top right corner).</li>
                                    <li>Select 'Add to Home screen'.</li>
                                    <li>Tap 'Add' when prompted.</li>
                                </ol>
                            </>
                        )}
                        <button onClick={() => setShowInstructions(false)} className={styles.closeButton}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CollectionInfo;
